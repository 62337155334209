import { data } from 'jquery';
import {React,useState,useEffect} from 'react'
import { io } from "socket.io-client";
import Swal  from 'sweetalert2';
import { useNavigate  } from 'react-router-dom';
import $ from 'jquery'; 
import { Outlet, Link } from "react-router-dom";
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import Table from 'react-bootstrap/Table';
import axios, {isCancel, AxiosError} from 'axios';
//import SweetAlert2 from 'react-sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function Home() {
  

//const [updates, setUpdates] = useState([])
const [chk1, setChk1] = useState("");
const [chk2, setChk2] = useState("");
const [chk3, setChk3] = useState("");
const [patient, setPatient] = useState([]);
const [search_data,setSearch] = useState('');
const [treatments,setTreatments] = useState([]) 
const [render_treatments,setRender_treatments] = useState([
]) 
const [selectedValues, setSelectedValues] = useState(Array(data.length).fill(""));
const [selectedValues2, setSelectedValues2] = useState(Array(data.length).fill(""));
const [selectedValues3, setSelectedValues3] = useState(Array(data.length).fill(""));

const [patients, setPatients] = useState([]);
const [department, setDepartment] = useState([]);
const [checked, setChecked] = useState(true);
const notify = () => toast("Wow so easy!");
useEffect(() => {
  console.log(process.env.NODE_ENV);
  PatientsGet();
  createSelectItems();

  const interval = setInterval(() => {
    PatientsGet();
  },3000);

  return () => clearInterval(interval);
  
}, []);

const sendingER = async (rowIndex, vn) => {
  toast.error('ส่งต่อแผนกฉุกเฉินสำเร็จ');
  await axios.put(process.env.REACT_APP_API_URL+'/send_er', {vn:vn})
  .then(
    (result) => {
      
     
      console.log(result);
       PatientsGet();
    }
  )
}
const sendingLAB = async (rowIndex, vn) => {
  toast.info('ส่งต่อห้อง LAB สำเร็จ');
  await axios.put(process.env.REACT_APP_API_URL+'/send_lab', {vn:vn})
  .then(
    (result) => {
      
     
      console.log(result);
       PatientsGet();
    }
  )
}
const sendingARI =  async (rowIndex, vn) => {
  toast.info('ส่งต่อแผนก ARI สำเร็จ');
  await axios.put(process.env.REACT_APP_API_URL+'/send_ari', {vn:vn})
  .then(
    (result) => {
      
     
      console.log(result);
      PatientsGet();
    }
  )
}
const sendingOPD = async (rowIndex, vn) => {
  toast.info('ส่งต่อแผนก OPD สำเร็จ');
  await axios.put(process.env.REACT_APP_API_URL+'/send_opd', {vn:vn})
  .then(
    (result) => {
      
     
      console.log(result);
      PatientsGet();
    }
  )
}

const PatientsGet = () => {
  fetch(process.env.REACT_APP_API_URL + '/screen')
    .then(res => res.json())
    .then(
      (result) => {
      
       // alert('ok');
        setPatients(result)
        console.log(result);
      }
    )
}

const UserUpdateStatus = async (rowIndex, vn,pt_priority) => {
  if(pt_priority == 0){
    const hasCondition1 = pt_priority == 0;
    var chk =  hasCondition1 ? "btn-active": "";
    setSelectedValues2[rowIndex] = chk;
    toast.success('อัพเดทสถานะทั่วไป')
   
  }else if(pt_priority == 1){
    const hasCondition1 = pt_priority == 1;
    var chk =  hasCondition1 ? "btn-active": "";
    selectedValues2[rowIndex] = chk;

    toast.warning('อัพเดทสถานะเร่งด่วน')
  }else if(pt_priority == 2){
    const hasCondition1 = pt_priority == 2;
    var chk =  hasCondition1 ? "btn-active": "";
    //setSelectedValues3((prevFriends) => [1,2,4]);
    
    toast.error('อัพเดทสถานะเร่งด่วนมาก');
  }

 
  selectedValues3.splice(rowIndex,'ok');
  setSelectedValues3([...selectedValues3]);
  console.log('dee');
    console.log(selectedValues3);
    console.log('dee');

  
    await  axios.put(process.env.REACT_APP_API_URL+'/updateStatus', {vn:vn,pt_priority:pt_priority})
  .then(
    (result) => {
      
      //alert('ok');
      console.log(result);
      PatientsGet();
    }
   
)

 
}

const handleRadioChange = (event, rowIndex) => {
 // const newSelectedValues = [...selectedValues];
 // newSelectedValues[rowIndex] = event.target.value;
  alert(rowIndex);
 // setSelectedValues(newSelectedValues);
};
//const socket = io('http://122.154.56.205:6789', { transports : ['websocket','ws','wss','polling']});

// สร้าง method shouldComponentUpdate สำหรับตรวจสอบการเปลี่ยนแปลงของ props และ state
//shouldComponentUpdate(nextProps, nextState) {
  // ตรวจสอบว่า props และ state มีการเปลี่ยนแปลงหรือไม่
 // if (PatientsGet !== nextProps.PatientsGet ) {
    // กรณีมีการเปลี่ยนแปลงให้ return true เพื่ออนุญาตให้ component ถูก render ใหม่
   // return true;
  //}
  // กรณีไม่มีการเปลี่ยนแปลงให้ return false เพื่อป้องกันการ render ใหม่ของ component นี้
  //return false;
//}

var i = 1;
const createSelectItems  = () => {
  let items = [];    
  
  fetch(process.env.REACT_APP_API_URL+'/department')
  .then(res => res.json())
  .then(
    (result) => {
      setDepartment(result)
       // result.map((data,index) => {
          //alert(data.department)
         // items.push(<option key={data.decode} value={data.decode}>{data.department}</option>);   
        //})
   
    }
  )

 // return items;
}  

const date = new Date()

const result_date = date.toLocaleDateString('th-TH', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
})


let navigate = useNavigate();


  
  return (
    <>
    <div className="container content">
        <div className="row dee-home">
            <div className="col-md-12 ">
           
            <ToastContainer />
               
                    <div className="blog-news text-center">
                  
                
                             <h5>การคัดกรองคนไข้ {result_date}</h5>      
                            
                               
                            <div className="">
                                  <Table striped bordered hover>
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        <th width="10">คิว</th>
                                        <th>HN</th>
                                        <th>ชื่อ-นามสกุล</th>
                                        <th>แผนกหลัก</th>
                                        {/*  <th>แผนกปัจจุบัน</th> */}
                                        <th>สถานะ</th>
                                        <th>ส่งต่อ</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                    {patients .map((patient,index) => {
                                    //{ patients.map((patient,key) => (
                                      //const hasCondition = item.condition !== undefined && item.condition !== null;
                                       const hasCondition1 =patient.pt_priority == 0;
                                       var chk1 =  hasCondition1 ? "btn-active": "";
                                      // const newSelectedValues = [...selectedValues];
                                       selectedValues[index] = chk1;
                                       //setSelectedValues(newSelectedValues);

                                       const hasCondition2 = patient.pt_priority == 1;
                                       var chk2 =  hasCondition2 ? "btn-active": "";
                                       selectedValues2[index] = chk2;

                                       const hasCondition3 = patient.pt_priority == 2;
                                       var chk3 =  hasCondition3 ? "btn-active": "";
                                     //  selectedValues3[index] = chk3;


                                         // ตรวจสอบว่า item มีเงื่อนไขหรือไม่
                                      
                                      //  alert(selectedValues[index]);
                                    // alert(chk1);
                                      // defaultChecked={patient.pt_priorit == 1 ? "1":""}
                                     // patient =  patient.pt_priorit == 0 ? "xx":""
                                     return (
                                          <tr  key={index+1} >
                                            <td>{ index+1 }</td>
                                             {/*  <td> <img  className="circular_image" src="assets/images/person.png"/></td> */}
                                           <td className='q'><b>{patient.oqueue}</b></td>
                                            <td>{patient.hn}</td>
                                            <td>{patient.pname+patient.fname+'  '+patient.lname}</td>
                                             <td>{patient.department}</td> 
                                               {/*  <td>
                                            <select type="select" defaultValue={patient.cur_dep}   className='form-control' >
                                            { department.map((e, key2) => {
                                               
                                                  
                                                  return <option key={key2}  value={e.depcode}>{e.department}</option>;
                                            })}
                                            </select>
                                            </td>*/}
                                            <td>
                                        
                                              <button type="button" onClick={() => UserUpdateStatus(index,patient.vn,0)}   className={`btn btn-default ${selectedValues[index]}`} >ทั่วไป</button>
                                              <button type="button"  onClick={() => UserUpdateStatus(index,patient.vn,1)}   className={`btn btn-success ${selectedValues2[index]}`}>เร่งด่วน</button>
                                              <button type="button"  onClick={() => UserUpdateStatus(index,patient.vn,2)}   className={`btn btn-warning ${chk3}`}>เร่งด่วนมาก</button>
                                          
                                              {/*   <div className="form-check form-check-inline">
                                                  <input  type="radio" 
                                                  className="form-check-input"   
                                                  onChange={(event) => handleRadioChange(event, patient.pt_priority)}  
                                                  checked={selectedValues[index] === 0} 
                                                   name={`group-${index}`} 
                                                   id={`1_${patient.hn}`} value="0" />
                                                  <label className="form-check-label" htmlFor={`1_${patient.hn}`}>ทั่วไป</label>
                                             </div>
                                             <div className="form-check form-check-inline">
                                                  <input  type="radio" 
                                                  className="form-check-input"   
                                                  onChange={(event) => handleRadioChange(event, patient.pt_priority)}  
                                                  checked={selectedValues[index] === 1} 
                                                   name={`group-${index}`} 
                                                   id={`1_${patient.hn}`} value="1" />
                                                  <label className="form-check-label" htmlFor={`1_${patient.hn}`}>เร่งด่วน</label>
                                             </div>
                                             <div className="form-check form-check-inline">
                                                  <input  type="radio" 
                                                  className="form-check-input"   
                                                  onChange={(event) => handleRadioChange(event, patient.pt_priority)}  
                                                  checked={selectedValues[index] === 2} 
                                                   name={`group-${index}`} 
                                                   id={`1_${patient.hn}`} value="2" />
                                                  <label className="form-check-label" htmlFor={`1_${patient.hn}`}>เร่งด่วนมาก</label>
                                             </div>*/}
                                             
                                             
                               
                                            
                                            </td>
                                            <td>
                                              <button type="button"  onClick={() => sendingOPD(index,patient.vn)}   className={`btn btn-success`}><img  className=" er_img" src="./assets/images/refer.png"/> OPD</button>
                                              <button type="button"  onClick={() => sendingARI(index,patient.vn)}   className={`btn btn-primary`}><img  className=" er_img" src="./assets/images/refer.png"/> ARI</button>
                                              <button type="button"  onClick={() => sendingER(index,patient.vn,2)}   className={`btn btn-danger`}><img  className=" er_img" src="./assets/images/refer.png"/> ER</button>
                                              <button type="button"  onClick={() => sendingLAB(index,patient.vn,2)}   className={`btn btn-warning`}><img  className=" er_img" src="./assets/images/refer.png"/> LAB</button>
                                           
                                            </td> 
                                          </tr>
                                        )
                                       })}
                                    </tbody>
                                  </Table>
                         
                                
                                
                                
                                
                            
                            </div>
                    </div> 
            </div>
        </div>
    </div>
    </>
  )
}
