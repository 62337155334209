import logo from './logo.svg';
//import './App.css';
import React,{Component} from 'react';
import Header from './component/Header'; 
import Home from './component/Home';
import $ from 'jquery'; 
import Swal  from 'sweetalert2';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Medicalrecode from './component/Medicalrecode';
import Appointment from './component/Appointment';
import Cc from './component/Cc';
import Diag from './component/Diag';
import Backhome from './component/Backhome';

function App() {
 console.log('start');
  return (
    <>
     <BrowserRouter>
        {  <Header/> }
          <Routes>
              <Route index element={<Home />} />
              { <Route path="Appointment" element={<Appointment />} /> }
              { <Route path="Cc" element={<Cc />} /> }
              { <Route path="Diag" element={<Diag />} /> }
              { <Route path="Backhome" element={<Backhome />} /> }
          </Routes>
       
       
     </BrowserRouter>
      
    </>
  );
}

export default App;
