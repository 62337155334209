import { data } from 'jquery';
import {React,useState,useEffect} from 'react'
import { io } from "socket.io-client";
import Swal  from 'sweetalert2';
import { useNavigate  } from 'react-router-dom';
import $ from 'jquery'; 
import { Outlet, Link } from "react-router-dom";
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import Table from 'react-bootstrap/Table';
import axios, {isCancel, AxiosError} from 'axios';
//import SweetAlert2 from 'react-sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import OverwriteMomentBE from './OverwriteMomentBE';
import { DatePicker,MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import thLocale from 'date-fns/locale/th';
import { TextField } from '@material-ui/core';
import { format } from 'date-fns';
import LoadingSpinner from './LoadingSpinner';


export default function Diag() {
   

//const [selectedDate,handleDateChange] = useState(moment().add(0,"years"));
const formatThaiYear = (date) => {
  const thaiYear = date.getFullYear() + 543;
  return `${date.getDate()} ${thLocale.localize.month(date.getMonth(), { width: 'wide' })} ${thaiYear}`;
};

const getThaiYearHeader = (date, invalidLabel) => {
  if (!date) {
    return invalidLabel;
  }

  const thaiYear = date.getFullYear() + 543;
  const formattedDate = format(date, 'dd MMMM', { locale: thLocale });

  return `${formattedDate} ${thaiYear}`;
};


const renderInput = (props) => {
  return <input {...props} />;
};
//const [updates, setUpdates] = useState([])


const [department, setDepartment] = useState([]);
const [clinic, setClinic] = useState([]);
const [checked, setChecked] = useState(true);
const notify = () => toast("Wow so easy!");
const [formData, setFormData] = useState({});
const [selectedDate, handleDateChange] = useState(new Date());
const [patients, setPatients] = useState([]);
const [loading, setLoading] = useState(false);

const [dateStart, setDateStart] = useState("");
const [dateEnd, setDateEnd] = useState("");

let navigate = useNavigate();

useEffect(() => {
  console.log(process.env.NODE_ENV);

  const date = new Date();
  //setFormData({ ...formData, ['dateStart']: format(date, 'yyyy-MM-dd'), ['dateEnd']: format(date, 'yyyy-MM-dd') });

  setDateStart(format(date, 'yyyy-MM-dd'))
  setDateEnd(format(date, 'yyyy-MM-dd'))

}, []);


const formatDate = (event) => {
  const date = new Date(event);
  var d  = date.toLocaleString();
  //date = date.toLocaleString().split(' ')[0];
   return  d.toLocaleString().split(' ')[0];
}

const handleInputDate = (event) => {
   console.log(event);
   const { name, value } = event.target;
   handleDateChange(event);
   setFormData({ ...formData, [name]: format(event, 'yyyy-MM-dd') });
  /* const { name, value } = event.target;
  
  setFormData({ ...formData, [name]: value }); */
};

const handleDateStart = (event) => {
   setDateStart(format(event, 'yyyy-MM-dd'))
}
const handleDateEnd = (event) => {
  setDateEnd(format(event, 'yyyy-MM-dd'))
}
function print(){
  window.print();
}

async function handleSearch(event) {
  event.preventDefault();
  //console.log(event);
  if (dateStart === null) {
    console.log("dateStart is null");
  } else {
    console.log("dateStart is", dateStart);
  }
 // alert(dateStart);
  setLoading(true);
  setPatients([]);
  //return false;
  const response = await fetch(process.env.REACT_APP_API_URL+'/diag?dateStart='+dateStart+'&dateEnd='+dateEnd).then(response => response.json()).then(
    (result) => {
      setLoading(false);
      if(result.length > 0){
        setPatients(result)
      }else{
        setPatients([])
        Swal.fire(
          'ไม่พบข้อมูลที่ท่านเลือก !',
          '',
          'error'
         )
      }
      
   
    }
  ).catch(error => console.error(error));
  //const json = await response.json();
 // setData(json);

  /* 
  fetch(process.env.REACT_APP_API_URL+'/cc', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(formData)
  })
    .then(response => response.json())
    .then(
      (result) => {
        setLoading(false);
        if(result.length > 0){
          setPatients(result)
        }else{
          setPatients([])
          Swal.fire(
            'ไม่พบข้อมูลที่ท่านเลือก !',
            '',
            'error'
           )
        }
        
     
      }
    ).catch(error => console.error(error));
   */
  /* Swal.fire(
    'ทำรายการสำเร็จ !',
    '',
    'success'
   ) */


  };

  return (
    <>

    <div className="container content">
        <div className="row dee-home">
            <div className="col-md-12 ">
           
            <ToastContainer />
               
                    <div className="blog-news ">
                        <h5 className='text-center'>รายชื่อคนไข้ที่ไม่มี Diag </h5>    
                   
              <form onSubmit={handleSearch}>
                
                        <div className="form-group">
                          <div className='row'>
                          <div className='col-sm-4'>
                                <label className='label' htmlFor=""><b>จากวันที่</b></label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={thLocale}>
                                  <DatePicker
                                    format="dd MMMM yyyy"
                                    margin="normal"
                                    id="dateStart"
                                    name="dateStart"
                                    value={dateStart}
                                    className="form-control"
                                   // name='dateStart' 
                                   autoOk='true' 
                                    onChange={handleDateStart}
                                    labelFunc={formatThaiYear}
                                
                                  />
                                </MuiPickersUtilsProvider>
                             </div>
                             <div className='col-sm-4'>
                                <label className='label' htmlFor=""><b>ถึงวันที่</b></label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={thLocale}>
                                  <DatePicker
                                    format="dd MMMM yyyy"
                                    margin="normal"
                                    id="dateEnd"
                                    name="dateEnd"
                                    value={dateEnd}
                                    className="form-control"
                                   // name='dateEnd' 
                                   autoOk='true' 
                                    onChange={handleDateEnd}
                                    labelFunc={formatThaiYear}
                                
                                  />
                                </MuiPickersUtilsProvider>
                             </div>
                        
                             <div className='col-sm-2'  style={{padding: "20px"}}>
                                  <button type="submit"   className='btn btn-primary' ><i className='fa fa-search'></i> ค้นหา</button>
                                  <button type="button"  onClick={print}  className='btn btn-info' ><i className='fa fa-print-o'></i> พิมพ์</button>
                              </div>
                           </div>
                    
                       
              
                       </div>
                      
              </form>  
              {loading && <LoadingSpinner />} {/* แสดง LoadingSpinner ก็ต่อเมื่อ isLoading เป็น true */}
                  {patients.length > 0 && (
                        <div className='col-sm-12'>
                          
                             
                              <div className="">
                                                    <Table striped bordered hover>
                                                      <thead>
                                                        <tr>
                                                          <th  width="5">#</th>
                                                          <th width="10">แผนก</th>
                                                          <th width="10">วันที่รับบริการ</th>
                                                          <th width="10">เวลา</th>
                                                          <th  width="10">HN</th>
                                                          <th  width="15">ชื่อ-นามสกุล</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                      {patients .map((patient,index) => {
                                                      return (
                                                            <tr  key={index+1} >
                                                              <td>{ index+1 }</td>
                                                              <td>{patient.department}</td>
                                                              <td>{formatDate(patient.vstdate)}</td>
                                                              <td>{patient.vsttime}</td>
                                                              <td>{patient.hn}</td>
                                                              <td>{patient.pname+patient.fname+'  '+patient.lname}</td>
                                                              
                                                            </tr>
                                                          )
                                                        })}
                                                      </tbody>
                                                    </Table>
                                  </div>
                              </div>
                    )}
               </div> 

                    
            </div>
           
        </div>
    </div>
    </>
  )
}
