import React from 'react'
import $ from 'jquery'; 
import { Outlet, Link } from "react-router-dom";

/* When the user clicks on the button,
toggle between hiding and showing the dropdown content */
function myFunction() {
    document.getElementById("myDropdown").classList.toggle("show");
  }
  

  window.onclick = function(event) {
    if (!event.target.matches('.dropbtn')) {
      var dropdowns = document.getElementsByClassName("dropdown-content");
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('show')) {
          openDropdown.classList.remove('show');
        }
      }
    }
  }
  

  function MenuFunction() {
    var element = document.getElementById("mobile-menu");
  
    if (element.classList) { 
      element.classList.toggle("menu-click");
    } else {
      var classes = element.className.split(" ");
      var i = classes.indexOf("menu-click");
  
      if (i >= 0) 
        classes.splice(i, 1);
      else 
        classes.push("menu-click");
        element.className = classes.join(" "); 
    }
  }
  
  
    $('.menu-mobile .menu-item-has-children').append('<i className="fa fa-angle-down"></i>');
    $('.menu-mobile .menu-item-has-children > i').on( 'click', function() {
      $(this).parent().toggleClass('active');
    });
    $('.menu-mobile .menu-item-has-children > a').on( 'click', function(e) {
      if($(this).attr('href') == '#') {
        e.preventDefault();
        $(this).parent().toggleClass('active');        
      }
    });
function header() {
  return (
    <>
      <div className="dee-header">
        <div className="container">
                        <div className="headbar-mobile">
                        <div className="container">
                        <div id="mobile-menu" className="mobile-menu-nav">
                            <div className="content-nav">
                            <div className="head-left">
                                <a className="navbar-brand" href=""><img className="brand-img"   src="assets/images/logo.png" width="120"/></a>
                            </div>
                            <div className="head-right">
                                <button onClick={MenuFunction}  className="btn-mobile-menu">
                                <i>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </i>
                                <b>Menu</b>
                                </button>
                            </div>
                            </div>
                            <div className="content-menu">
                            <div className="main-menu menu-mobile">
                                <div className="menu-mobile-row">
                                <ul className="menu">
                                    <li className="menu-item"> <Link to="/" >หน้าหลัก</Link></li>
                                    <li className="menu-item"><Link   to="./appointment" >ยกเลิกการนัด</Link></li>
                                    <li className="menu-item"><Link   to="./cc" >คนไข้ที่ไม่มี  CC</Link></li>
                                    <li className="menu-item"><Link   to="./diag" >คนไข้ที่ไม่มี Diag</Link></li>
                                    <li className="menu-item"><Link   to="./backhome" >คนไข้ไม่กลับบ้าน</Link></li>
                                   
                                    
                                </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        </div>
                    <nav className="navbar navbar-expand-sm">

                    <a className="navbar-brand" href=""><img className="brand-img" src="assets/images/logo.png" width="120"/></a>

                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">

                    

                    <div className="menu-name"><b>เมนู</b></div>

                    </button>

                    <div className="collapse navbar-collapse" id="collapsibleNavbar">

                    <ul className="navbar-nav nav-right">

                        <li className="nav-item">

                        <Link to="/" className="nav-link _mainweb" href="">หน้าหลัก</Link>

                        </li>
                        
                        <li className="nav-item">
                          <Link   to="./appointment" className="nav-link _mainweb">ยกเลิกการนัด</Link>
                        </li>
                        <li className="nav-item">
                          <Link   to="./cc" className="nav-link _mainweb">คนไข้ที่ไม่มี CC</Link>
                        </li>
                        <li className="nav-item">
                          <Link   to="./diag" className="nav-link _mainweb">คนไข้ที่ไม่มี Diag</Link>
                        </li>
                        <li className="nav-item">
                          <Link   to="./backhome" className="nav-link _mainweb">คนไข้ไม่กลับบ้าน</Link>
                        </li>
                       




                    </ul>

                    </div>  

                    </nav>

        </div>
    </div>
    </>
  )
}

export default header
