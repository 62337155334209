import { data } from 'jquery';
import {React,useState,useEffect} from 'react'
import { io } from "socket.io-client";
import Swal  from 'sweetalert2';
import { useNavigate  } from 'react-router-dom';
import $ from 'jquery'; 
import { Outlet, Link } from "react-router-dom";
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import Table from 'react-bootstrap/Table';
import axios, {isCancel, AxiosError} from 'axios';
//import SweetAlert2 from 'react-sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import OverwriteMomentBE from './OverwriteMomentBE';
import { DatePicker,MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import thLocale from 'date-fns/locale/th';
import { TextField } from '@material-ui/core';
import { format } from 'date-fns';
import LoadingSpinner from './LoadingSpinner';


export default function Appointment() {
   

//const [selectedDate,handleDateChange] = useState(moment().add(0,"years"));
const formatThaiYear = (date) => {
  const thaiYear = date.getFullYear() + 543;
  return `${date.getDate()} ${thLocale.localize.month(date.getMonth(), { width: 'wide' })} ${thaiYear}`;
};

const getThaiYearHeader = (date, invalidLabel) => {
  if (!date) {
    return invalidLabel;
  }

  const thaiYear = date.getFullYear() + 543;
  const formattedDate = format(date, 'dd MMMM', { locale: thLocale });

  return `${formattedDate} ${thaiYear}`;
};


const renderInput = (props) => {
  return <input {...props} />;
};
//const [updates, setUpdates] = useState([])


const [department, setDepartment] = useState([]);
const [clinic, setClinic] = useState([]);
const [checked, setChecked] = useState(true);
const notify = () => toast("Wow so easy!");
const [formData, setFormData] = useState({});
const [selectedDate, handleDateChange] = useState(new Date());
const [patients, setPatients] = useState([]);
const [loading, setLoading] = useState(false);

let navigate = useNavigate();

useEffect(() => {
  console.log(process.env.NODE_ENV);
  createSelectItems();
 // createSelectItems2();
  const date = new Date();
  setFormData({ ...formData, ['appointmentDate']: format(date, 'yyyy-MM-dd'), ['clinic']: '001' });


}, []);
const createSelectItems  = () => {
  let items = [];    
  
  fetch(process.env.REACT_APP_API_URL+'/clinic')
  .then(res => res.json())
  .then(
    (result) => {
      setClinic(result)
       // result.map((data,index) => {
          //alert(data.department)
         // items.push(<option key={data.decode} value={data.decode}>{data.department}</option>);   
        //})
   
    }
  )

 // return items;
}  
const createSelectItems2  = () => {
  let items = [];    
  
  fetch(process.env.REACT_APP_API_URL+'/department')
  .then(res => res.json())
  .then(
    (result) => {
      setDepartment(result)
       // result.map((data,index) => {
          //alert(data.department)
         // items.push(<option key={data.decode} value={data.decode}>{data.department}</option>);   
        //})
   
    }
  )

 // return items;
}  

const formatDate = (event) => {
  const date = new Date(event);
  var d  = date.toLocaleString();
  //date = date.toLocaleString().split(' ')[0];
   return  d.toLocaleString().split(' ')[0];
}
const handleInputChange = (event) => {
  const { name, value } = event.target;
  setFormData({ ...formData, [name]: value });
};
const handleInputDate = (event) => {
   console.log(event);
   handleDateChange(event);
   setFormData({ ...formData, ['appointmentDate']: format(event, 'yyyy-MM-dd') });
  /* const { name, value } = event.target;
  
  setFormData({ ...formData, [name]: value }); */
};

const handleSearch = (event) => {
  event.preventDefault();
  setLoading(true);
  setPatients([])
  fetch(process.env.REACT_APP_API_URL+'/appointment', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(formData)
  })
    .then(response => response.json())
    .then(
      (result) => {
        setLoading(false);
        if(result.length > 0){
          setPatients(result)
        }else{
          setPatients([])
          Swal.fire(
            'ไม่พบข้อมูลที่ท่านเลือก !',
            '',
            'error'
           )
        }
        
     
      }
    ).catch(error => console.error(error));
 
  /* Swal.fire(
    'ทำรายการสำเร็จ !',
    '',
    'success'
   ) */


  };
  
const handleSubmit = (event) => {
    event.preventDefault();
  
    Swal.fire({
      title: 'ยืนยันยกเลิกการนัด?',
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน'
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(process.env.REACT_APP_API_URL+'/update_appointment', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        })
          .then(response => response.json())
          .then(data => console.log(data))
          .catch(error => console.error(error));
       
        Swal.fire(
          'ทำรายการสำเร็จ !',
          '',
          'success'
        )
        
        fetch(process.env.REACT_APP_API_URL+'/appointment', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        })
          .then(response => response.json())
          .then(
            (result) => {
              setLoading(false);
              if(result.length > 0){
                setPatients(result)
              }else{
                setPatients([])
                Swal.fire(
                  'ไม่พบข้อมูลที่ท่านเลือก !',
                  '',
                  'error'
                 )
              }
              
           
            }
          ).catch(error => console.error(error));

      
      }
    })
  
  
  
    };
  return (
    <>

    <div className="container content">
        <div className="row dee-home">
            <div className="col-md-12 ">
           
            <ToastContainer />
               
                    <div className="blog-news ">
                        <h5 className='text-center'>ยกเลิกการนัด </h5>    
                   
              <form onSubmit={handleSearch}>
                
                        <div className="form-group">
                          <div className='row'>
                          <div className='col-sm-4'>
                                <label className='label' htmlFor=""><b>วันที่</b></label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={thLocale}>
                                  <DatePicker
                                    format="dd MMMM yyyy"
                                    margin="normal"
                                    id="appointmentDate"
                                    name="appointmentDate"
                                    value={selectedDate}
                                    className="form-control"
                                    autoOk='true' 
                                   // name='appointmentDate' 
                                    onChange={handleInputDate}
                                    labelFunc={formatThaiYear}
                                
                                  />
                                </MuiPickersUtilsProvider>
                             </div>
                           <div className='col-sm-4'>
                                <label className='label' htmlFor=""><b>คลินิก</b></label>
                                <select type="select" name='clinic'  onChange={handleInputChange}  className='form-input MuiInputBase-input  MuiInput-input MuiInput-underline' >
                                            { clinic.map((e, key2) => {
                                               
                                                  
                                                  return <option key={key2}  value={e.clinic}>{e.name}</option>;
                                            })}
                                </select>
                             </div>
                        
                             <div className='col-sm-2'  style={{padding: "20px"}}>
                                  <button type="submit"   className='btn btn-primary' ><i className='fa fa-search'></i> ค้นหา</button>
                              </div>
                           </div>
                    
                       
              
                       </div>
                      
              </form>  
              {loading && <LoadingSpinner />} {/* แสดง LoadingSpinner ก็ต่อเมื่อ isLoading เป็น true */}
                  {patients.length > 0 && (
                        <div className='col-sm-12'>
                            <div className="form-group">
                                <div className='row'>
                                {/*   <div className='col-sm-4'>
                               
                                 </div> */}
                                  <div className='col-sm-2'>
                                       <button type="button" onClick={handleSubmit}  className='btn btn-success' ><i className='fa fa-search'></i> ย้ายไปยังจุดซักประวัติผู้ป่วยนอก</button>
                                  </div>
                                </div>
                           </div>
                             
                              <div className="">
                                                    <Table striped bordered hover>
                                                      <thead>
                                                        <tr>
                                                          <th  width="5">#</th>
                                                          <th width="10">วันที่นัด</th>
                                                          <th width="15">แผนก</th>
                                                          <th width="15">คลินิก</th>
                                                          <th  width="10">HN</th>
                                                          <th  width="15">ชื่อ-นามสกุล</th>
                                                          <th  width="15">สาเหตุการนัด</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                      {patients .map((patient,index) => {
                                                      return (
                                                            <tr  key={index+1} >
                                                              <td>{ index+1 }</td>
                                                              <td>{formatDate(patient.nextdate)}</td>
                                                              <td>{patient.department}</td>
                                                              <td>{patient.clinic}</td>
                                                              <td>{patient.hn}</td>
                                                              <td>{patient.pname+patient.fname+'  '+patient.lname}</td>
                                                              <td>{patient.app_cause}</td>
                                                              
                                                            </tr>
                                                          )
                                                        })}
                                                      </tbody>
                                                    </Table>
                                  </div>
                              </div>
                    )}
               </div> 

                    
            </div>
           
        </div>
    </div>
    </>
  )
}
